import "./PrizeList.css";
import "./RafflePopup.css";
import { colors } from "../color";
import Popup from "reactjs-popup";
import close from "../assets/Close.svg";

export default function RafflePopup({ prizeProps, closeModal }) {
  const totalRecords = 7;

  return (
    <Popup
      open={prizeProps.open}
      modal
      {...{
        overlayStyle: {
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: "999999",
        },
      }}
      onClose={closeModal}
    >
      <div
        className="popup_entire_container hkid_popup_container"
        style={{ backgroundColor: colors.whitecolor }}
      >
        <div className="popup_header">
          <div className="popup_header_title">{prizeProps.title}</div>
          <button
            type="button"
            style={{ backgroundColor: colors.whitecolor }}
            onClick={() => {
              closeModal();
            }}
          >
            <img src={close} alt={"close"} />
          </button>
        </div>
        <div className="raffle_content">
          {
            // loop through the total records
            Array.from({ length: totalRecords }).map((_, index) => (
              <div className="raffle_each_content" key={index}>
                <div className="raffle_word_container">
                  <div className="raffle_title">這是一個標題</div>
                  <div
                    className="raffle_desc"
                    style={{ color: colors.grey2color }}
                  >
                    重科喜借定。何瓜功或路象乾日位陽好央給、六化片笑說冒幫巴放，多話子京文干弟念只弓三高原有，田像幼有直，珠哭兆第們、去未用停間禾怪飛牛。
                  </div>
                </div>
                <div className="raffle_img_container">
                  <img
                    src="https://via.placeholder.com/150"
                    alt="prize"
                    style={{ width: "289px", height: "auto" }}
                  />
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Popup>
  );
}
