import "./Profile.css";
import { colors } from "../color";
import coins from "../assets/coins.svg";
import userLogo from "../assets/user_logo.png";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/storeUser";
import LoginButton from "./LoginButton";
import Popup from "reactjs-popup";
import LuckdrawProgress from "./LuckdrawProgress";
import RecordPopup from "./RecordPopup";
import { useState } from "react";

export default function Profile() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [openPrizeRecord, setOpenPrizeRecord] = useState(false);
  const closePrizeModal = () => setOpenPrizeRecord(false);

  const [isPrize, setIsPrize] = useState(false);

  const logOut = () => {
    window.viutv.logout();
    dispatch(logout());
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
  };

  return (
    <div className="profileContainer">
      {user.info == null ? (
        <LoginButton loginButtonText="登入" />
      ) : (
        <Popup
          trigger={(open) => (
            <button
              type="button"
              className="profile"
              style={{
                backgroundColor: colors.whitecolor,
              }}
            >
              <div className="coins_container">
                <img src={coins} alt="tickets" />
                <div style={{ color: colors.blackcolor }}>我的印花</div>
                <div style={{ color: colors.blackcolor }}>1200 個</div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="26"
                viewBox="0 0 2 26"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M1.11719 1V25"
                  stroke="#0A7DEB"
                  strokeLinecap="round"
                />
              </svg>
              <div className="img_container">
                <img src={userLogo} alt="user logo"></img>
              </div>
              <div style={{ color: colors.blackcolor }}>
                {user.info == null ? "login" : user.info.name.first}
              </div>
            </button>
          )}
          position="bottom center"
          closeOnDocumentClick
          {...{ contentStyle: { zIndex: 99999 } }}
        >
          <div
            className="profile_popup_container"
            style={{
              backgroundColor: colors.whitecolor,
              border: `1px solid ${colors.viublue}`,
              color: colors.blackcolor,
            }}
          >
            <div
              className="profile_join_button_container"
              style={{ borderBottom: `1px solid ${colors.viublue}` }}
            >
              {/* <button
                type="button"
                className="profile_join_button"
                style={{
                  backgroundColor: colors.viublue,
                  color: colors.whitecolor,
                }}
              >
                <div>參加《集氣電視賞》</div>
              </button> */}
              <LuckdrawProgress />
            </div>
            <button
              type="button"
              className="profile_section"
              style={{
                borderBottom: `1px solid ${colors.viublue}`,
                backgroundColor: colors.whitecolor,
              }}
              onClick={() => {
                setOpenPrizeRecord(true);
                setIsPrize(true);
              }}
            >
              禮物兌換紀錄
            </button>
            <button
              type="button"
              className="profile_section"
              style={{
                borderBottom: `1px solid ${colors.viublue}`,
                backgroundColor: colors.whitecolor,
              }}
              onClick={() => {
                setOpenPrizeRecord(true);
                setIsPrize(false);
              }}
            >
              印花進帳記錄
            </button>
            <button
              type="button"
              className="profile_section"
              style={{
                backgroundColor: colors.whitecolor,
                borderRadius: "0px 0px 10px 10px",
              }}
              onClick={logOut}
            >
              登出
            </button>
          </div>
        </Popup>
      )}
      <RecordPopup
        prizeProps={{ open: openPrizeRecord, isPrize: isPrize }}
        closeModal={closePrizeModal}
      />
    </div>
  );
}
