import { colors } from "../color";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer" style={{ backgroundColor: colors.darkblue }}>
      <div>© 2024 HKTVE. All rights reserved</div>
      <div className="rightContainer">
        <div>條款細則</div>
        <div style={{ marginLeft: "40px" }}>私隱政策</div>
      </div>
    </div>
  );
}
