import "./NavigationBar.css";
import { colors } from "../color";
import ScrollTo from "react-scroll-into-view";
import { useState, useEffect } from "react";

export default function NavigationBar(props) {
  const [selected, setSelected] = useState(0);

  // onClick function
  const handleClick = (index) => {
    setSelected(index);
  };

  // handle the selected button
  const selectedButton = (index) => {
    console.log(selected, index);
    return selected === index
      ? {
          backgroundColor: colors.viublue,
          color: colors.whitecolor,
          transition: "0.3s ease-in-out",
        }
      : { backgroundColor: colors.whitecolor, color: colors.blackcolor };
  };

  // useEffect(() => {
  //   setSelected(props.scrolledIndex);
  // }, [props.scrolledIndex]);

  return (
    <div
      className="navigation"
      style={{
        borderColor: colors.viublue,
        backgroundColor: colors.whitecolor,
      }}
    >
      <ScrollTo selector="#main_page" alignToTop={true}>
        <button
          className="navi_section"
          style={selectedButton(0)}
          type="button"
          onClick={() => handleClick(0)}
        >
          主頁
        </button>
      </ScrollTo>
      <ScrollTo selector="#luckdraw_page" alignToTop={true}>
        <button
          className="navi_section"
          style={selectedButton(1)}
          type="button"
          onClick={() => handleClick(1)}
        >
          終極大抽獎
        </button>
      </ScrollTo>
      <ScrollTo selector="#cheer_page" alignToTop={true}>
        <button
          className="navi_section"
          type="button"
          style={selectedButton(2)}
          onClick={() => handleClick(2)}
        >
          集氣獎賞
        </button>
      </ScrollTo>
      <ScrollTo selector="#prizes_page" alignToTop={true}>
        <button
          className="navi_section"
          type="button"
          style={selectedButton(3)}
          onClick={() => handleClick(3)}
        >
          兌換禮物
        </button>
      </ScrollTo>
    </div>
  );
}
