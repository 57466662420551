import "./PrizeList.css";
import { colors } from "../color";
import { useState, useEffect } from "react";
import coins from "../assets/coins.svg";
import PopupModel from "./PopupModel";

export default function PrizeList() {
  const [prizeList, setPrizeList] = useState([]);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const [clickedPrize, setClickedPrize] = useState(null);

  useEffect(() => {
    // add 10 prizes to the prizeList
    const tempPrizeList = [];
    for (let i = 0; i < 10; i++) {
      tempPrizeList.push({
        id: i,
        name: `Prize ${i}`,
        description: `Description of Prize ${i}`,
        image: `https://via.placeholder.com/150?text=Prize${i}`,
      });
    }
    setPrizeList(tempPrizeList);
    // fetch("https://api.example.com/prizes")
    //     .then((response) => response.json())
    //     .then((data) => {
    //         setPrizeList(data);
    //     });
  }, []);
  return (
    <div className="prizelist_container" style={{ color: colors.blackcolor }}>
      {prizeList.map((prize) => (
        <button
          type="button"
          className="prize_container"
          key={prize.id}
          onClick={() => {
            console.log("clicked");
            setOpen((o) => !o);
            setClickedPrize(prize);
          }}
        >
          <div className="prize_img_container">
            <img className="prize_img" src={prize.image} alt={prize.name} />
          </div>
          <div className="prize_name">{prize.name}</div>
          <div className="prize_description_container">
            <div className="left_prize_container">
              <div
                className="prize_description"
                style={{ marginRight: "4px", color: colors.blackgreycolor }}
              >
                需要印花
              </div>
              <img src={coins} alt="tickets" />
              <div className="stamp_number">45</div>
            </div>
            <button
              type="button"
              className="redeem_button"
              style={{
                backgroundColor: colors.viublue,
                color: colors.whitecolor,
              }}
            >
              兌換
            </button>
          </div>
        </button>
      ))}
      <PopupModel
        prizeProps={{ open: open, clickedPrize: clickedPrize }}
        closeModal={closeModal}
      />
    </div>
  );
}
