import { createSlice } from "@reduxjs/toolkit";

export const storeUser = createSlice({
  name: "user",
  initialState: {
    info: null,
    access_token: null,
  },
  reducers: {
    login: (state, action) => {
      state.info = action.payload.info;
      state.access_token = action.payload.access_token;
    },
    logout: (state) => {
      state.info = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout } = storeUser.actions;

export default storeUser.reducer;
