import "./PrizeList.css";
import "./RecordPopup.css";
import { colors } from "../color";
import coins from "../assets/coins.svg";
import Popup from "reactjs-popup";
import close from "../assets/Close.svg";

export default function RecordPopup({ prizeProps, closeModal }) {
  const totalRecords = 7;

  return (
    <Popup
      open={prizeProps.open}
      modal
      {...{
        overlayStyle: {
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: "999999",
        },
      }}
      onClose={closeModal}
    >
      <div
        className="popup_entire_container record_popup_container"
        style={{ backgroundColor: colors.whitecolor }}
      >
        <div className="popup_header">
          <div className="popup_header_title">
            {prizeProps.isPrize ? "禮物兌換紀錄" : "印花進帳紀錄"}
          </div>
          <button
            type="button"
            style={{ backgroundColor: colors.whitecolor }}
            onClick={closeModal}
          >
            <img src={close} alt={"close"} />
          </button>
        </div>
        <div className=" record_content">
          {
            // loop through the total records
            Array.from({ length: totalRecords }).map((_, index) => (
              <div className="each_record_container" key={index}>
                <div className="left_record_container">
                  {prizeProps.isPrize && (
                    <div className="popup_img_container record_img_container">
                      <img
                        className="prize_img"
                        src={`https://via.placeholder.com/150?text=test`}
                        alt={"test"}
                      />
                    </div>
                  )}
                  <div>
                    <div className="record_title">Google Pixel 智能手錶</div>
                    <div style={{ color: colors.grey2color }}>
                      7月30日 13:58
                    </div>
                  </div>
                </div>
                <div className="popup_content_container record_content_container">
                  {prizeProps.isPrize && (
                    <div
                      className="record_status"
                      style={{
                        color: colors.viublue,
                        backgroundColor: colors.lightblue,
                      }}
                    >
                      處理中
                    </div>
                  )}
                  <div className="record_coins_container">
                    <img src={coins} alt="tickets" />
                    <div>-35</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Popup>
  );
}
