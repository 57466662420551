import "./App.css";
import Header from "./layouts/Header";
import { colors } from "./color";
import Footer from "./layouts/Footer";
import KV from "./assets/KV_1.png";
import CampaignName from "./assets/ViuTV_OlympicsKV_Source_suki_tagline_1.png";
import Frame990 from "./assets/Frame_990.png";
import Frame989 from "./assets/Frame_989.png";
import { useEffect, useState, useRef } from "react";
import PrizeList from "./components/PrizeList";
import LuckdrawCarousel from "./components/LuckdrawCarousel";
import CheerCarousel from "./components/CheerCarousel";
// import LuckdrawProgress from "./components/LuckdrawProgress";
import { useSelector, useDispatch } from "react-redux";
import { login } from "./store/storeUser";
import LoginButton from "./components/LoginButton";
import HKIDPopup from "./components/HKIDPopup";
import RafflePopup from "./components/RafflePopup";
import CampaignPopup from "./components/CampaignPopup";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function App() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [itemNumber, setItemNumber] = useState(0);

  const [openHKID, setOpenHKID] = useState(false);
  const closeHKIDModal = () => setOpenHKID(false);

  const [openRaffle, setOpenRaffle] = useState(false);
  const closeRaffleModal = () => setOpenRaffle(false);
  const [clickedTitle, setClickedTitle] = useState("");

  const [openCampaign, setOpenCampaign] = useState(false);
  const closeCampaignModal = () => setOpenCampaign(false);

  const homePageRef = useRef();
  const luckdrawPageRef = useRef();
  const cheerPageRef = useRef();
  const prizesPageRef = useRef();

  const sections = gsap.utils.toArray([
    "#home_page",
    "#luckdraw_page",
    "#cheer_page",
    "#prizes_page",
  ]);

  sections.forEach((section, i) => {
    ScrollTrigger.create({
      trigger: section,
      start: "top bottom-=10px",
      end: "+=50%",
      onEnter: () => setItemNumber(i),
      onLeaveBack: () => setItemNumber(i - 1),
    });
  });

  useEffect(() => {
    const script = document.createElement("script");

    script.src = process.env.REACT_APP_VIUTV_SDK_URL;
    script.async = true;

    document.body.appendChild(script);

    // get the user from local storage
    const localUser = JSON.parse(localStorage.getItem("user"));
    const localAccessToken = JSON.parse(localStorage.getItem("access_token"));
    // const localAccessToken = JSON.parse(localStorage.getItem("access_token"));

    // if the user is in the local storage, dispatch the user
    if (localUser && localAccessToken) {
      dispatch(login({ info: localUser, access_token: localAccessToken }));
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [dispatch]);

  return (
    // <div className="App" style={{ overflow: "hidden" }}>
    <div className="App">
      {/* <Header /> */}
      <Header scrolledIndex={itemNumber} />
      {/* <header className="App-header"></header> */}
      <div
        className="KV_container KV_container_KV"
        // style={{ background: `url(${process.env.PUBLIC_URL}/images/KV.jpg)`}}
        style={{ backgroundImage: `url(${KV})` }}
        ref={homePageRef}
        id="main_page"
      >
        <div className="frame990_container">
          <img
            src={Frame990}
            alt="Frame 990"
            style={{ width: "auto", height: "320px" }}
          />
        </div>
        <div className="home_button_container">
          <img
            src={CampaignName}
            alt="Campaign Name"
            style={{ width: "335px", height: "auto" }}
          />
          <div className="compaign_desc" style={{ color: colors.whitecolor }}>
            這是一段集氣電視賞的簡介這是一段集氣電視賞的簡介這是一
            <br />
            段集氣電視賞的簡介這是一段集氣電視賞的簡介
          </div>
          <button
            type="button"
            className="enter_button"
            style={{
              backgroundColor: colors.lightblue,
              color: colors.viublue,
            }}
            onClick={() => setOpenCampaign(true)}
          >
            活動詳情
          </button>
        </div>
        <div className="frame989_container">
          <img
            src={Frame989}
            alt="Frame 989"
            style={{ width: "auto", height: "356px" }}
          />
        </div>
      </div>
      <div
        className="KV_container KV_container_Luckdraw"
        style={{
          backgroundColor: colors.darkblue,
          position: "relative",
          height: "468px",
          paddingTop: "100px",
          overflow: "hidden",
        }}
        id="luckdraw_page"
        ref={luckdrawPageRef}
      >
        <div
          style={{
            color: colors.whitecolor,
            position: "absolute",
            width: "1000px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="left_luckdraw_container" style={{ width: "525px" }}>
            <div
              className="sub_title"
              style={{
                color: colors.whitecolor,
                borderBottom: `2px solid ${colors.darkbluelight}`,
              }}
            >
              終極大抽獎
            </div>
            <div className="title">
              按集氣，儲印花。
              <div style={{ color: colors.highlightorange }}>
                參加終極大抽獎！
              </div>
            </div>
            <div className="desc">
              立即參加《電視集氣賞》活動，集齊 350個印花， 即可參加終極大抽獎！
            </div>
            {user.info == null && (
              <div className="home_button_container join_button_container">
                <LoginButton loginButtonText="參加活動" />
                <button
                  type="button"
                  className="enter_button join_button"
                  style={{
                    backgroundColor: colors.lightblue,
                    color: colors.viublue,
                  }}
                  onClick={() => {
                    setOpenRaffle(true);
                    setClickedTitle("《終極大抽獎》詳情");
                  }}
                >
                  了解詳情
                </button>
              </div>
            )}
            {user.info != null && (
              <div className="home_button_container join_button_container">
                <button
                  type="button"
                  className="enter_button join_button"
                  style={{
                    backgroundColor: colors.viublue,
                    color: colors.whitecolor,
                  }}
                  onClick={() => setOpenHKID(true)}
                >
                  參加活動
                </button>
                <button
                  type="button"
                  className="enter_button join_button"
                  style={{
                    backgroundColor: colors.lightblue,
                    color: colors.viublue,
                  }}
                  onClick={() => {
                    setOpenRaffle(true);
                    setClickedTitle("《終極大抽獎》詳情");
                  }}
                >
                  了解詳情
                </button>
              </div>
            )}
            {/* {user.info != null && (
              <div
                className="home_button_container join_button_container"
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "16px",
                }}
              >
                                <button
                  type="button"
                  className="enter_button join_button"
                  style={{
                    backgroundColor: colors.viublue,
                    color: colors.whitecolor,
                  }}
                >
                  參加活動
                </button>
                <LuckdrawProgress position={"desktop"} />
                <button
                  type="button"
                  className="enter_button join_button"
                  style={{
                    backgroundColor: colors.lightblue,
                    color: colors.viublue,
                  }}
                    onClick={() => {
                    setOpenRaffle(true);
                    setClickedTitle("《終極大抽獎》詳情");
                  }}
                >
                  了解詳情
                </button>
              </div>
            )} */}
          </div>
          <div style={{ width: "50%", marginLeft: "5%" }}>
            <LuckdrawCarousel />
          </div>
        </div>
      </div>
      <div
        className="KV_container KV_container_Luckdraw"
        style={{
          backgroundColor: colors.lightblue,
          position: "relative",
          height: "468px",
          paddingTop: "100px",
          overflow: "hidden",
        }}
        id="cheer_page"
        ref={cheerPageRef}
      >
        <div
          style={{
            color: colors.blackcolor,
            position: "absolute",
            width: "1000px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="left_cheer_container">
            <div
              className="sub_title"
              style={{
                color: colors.darkblue,
                borderBottom: `2px solid ${colors.viulight}`,
              }}
            >
              集氣獎賞
            </div>
            <div className="title">齊齊撳集氣， 集滿目標，即派過百份獎賞！</div>
            <div className="desc" style={{ color: colors.blackgreycolor }}>
              立即參加《電視集氣賞》活動，撳集氣，集齊該時段的集氣目標，
              <br />
              即派發獎賞。
            </div>
            <div className="home_button_container join_button_container">
              <button
                type="button"
                className="enter_button join_button"
                style={{
                  backgroundColor: colors.whitecolor,
                  color: colors.viublue,
                }}
                onClick={() => {
                  setOpenRaffle(true);
                  setClickedTitle("《集氣獎賞》詳情");
                }}
              >
                了解詳情
              </button>
            </div>
          </div>
          <div style={{ width: "50%", marginLeft: "5%" }}>
            <CheerCarousel />
          </div>
        </div>
      </div>
      <div
        className="KV_container KV_container_Luckdraw"
        style={{
          backgroundColor: colors.whitecolor,
          paddingTop: "92px",
          paddingBottom: "60px",
        }}
        id="prizes_page"
        ref={prizesPageRef}
      >
        <div
          style={{
            color: colors.blackcolor,
          }}
        >
          <div className="entire_prize_container">
            <div className="left_prizes_container">
              <div
                className="sub_title"
                style={{
                  color: colors.darkblue,
                  borderBottom: `2px solid ${colors.viulight}`,
                }}
              >
                印花兌換禮物
              </div>
              <div className="title">撳集氣，儲印花。 兌換上百款禮品！</div>
              <div className="desc" style={{ color: colors.blackgreycolor }}>
                立即參加《電視集氣賞》活動，集齊 350個印花，即可參加終極大抽獎！
              </div>
            </div>
            <div className="right_prize_container">
              <div
                className="home_button_container join_button_container"
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                <button
                  type="button"
                  className="enter_button join_button"
                  style={{
                    backgroundColor: colors.lightblue,
                    color: colors.viublue,
                  }}
                  onClick={() => {
                    setOpenRaffle(true);
                    setClickedTitle("《印花兌換禮物》詳情");
                  }}
                >
                  了解詳情
                </button>
              </div>
            </div>
          </div>
          <PrizeList />
        </div>
      </div>
      <Footer />
      <HKIDPopup prizeProps={{ open: openHKID }} closeModal={closeHKIDModal} />
      <RafflePopup
        prizeProps={{ open: openRaffle, title: clickedTitle }}
        closeModal={closeRaffleModal}
      />
      <CampaignPopup
        prizeProps={{ open: openCampaign }}
        closeModal={closeCampaignModal}
      />
    </div>
  );
}

export default App;
