import "./PrizeList.css";
import "./HKIDPopup.css";
import { colors } from "../color";
import success from "../assets/success.png";
import Popup from "reactjs-popup";
import close from "../assets/Close.svg";
import { useState } from "react";

export default function HKIDPopup({ prizeProps, closeModal }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const submitHKID = () => {
    setIsSubmitted(true);
  };

  return (
    <Popup
      open={prizeProps.open}
      modal
      {...{
        overlayStyle: {
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: "999999",
        },
      }}
      onClose={closeModal}
    >
      <div
        className="popup_entire_container hkid_popup_container"
        style={{ backgroundColor: colors.whitecolor }}
      >
        <div className="popup_header">
          <div className="popup_header_title">確認參加《集氣電視賞》</div>
          <button
            type="button"
            style={{ backgroundColor: colors.whitecolor }}
            onClick={() => {
              closeModal();
              setIsSubmitted(false);
            }}
          >
            <img src={close} alt={"close"} />
          </button>
        </div>
        {isSubmitted ? (
          <>
            <div className="hkid_success_content">
              <img
                src={success}
                alt={"success"}
                style={{ width: "96px", height: "auto" }}
              />
              <div className="hkid_success_msg">已成功參加《集氣電視賞》</div>
            </div>
          </>
        ) : (
          <>
            <div className="hkid_content">
              <div className="hkid_input_container">
                <div className="hkid_title">
                  請提供香港身份證首4位數字或字母
                </div>
                <input type="text" className="hkid_input" />
              </div>
              <div className="hkid_desc" style={{ color: colors.grey2color }}>
                。我們只會將你的香港身份證號碼用於獎品領取的身份核實。
                <br />
                。確認參加後，你將能參與《印花兌換禮物》、《集氣獎賞》、以及《終極大抽獎》
              </div>
            </div>
            <div className="popup_footer">
              <button
                type="button"
                className="popup_button"
                style={{
                  backgroundColor: colors.viublue,
                  color: colors.whitecolor,
                }}
                onClick={() => {
                  submitHKID();
                }}
              >
                確認參加
              </button>
            </div>
          </>
        )}
      </div>
    </Popup>
  );
}
