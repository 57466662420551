import "./Profile.css";
import { colors } from "../color";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "../store/storeUser";

export default function LoginButton(props) {
  const dispatch = useDispatch();
  const [isLogInShown, setIsLogInShown] = useState(false);

  useEffect(() => {
    if (isLogInShown) {
      return;
    }
    if (window.viutv) {
      if (window.viutv.user) {
        const viutvUser = window.viutv.user;
        const viutvAccessToken = window.viutv.session.access_token;
        dispatch(
          login({
            info: viutvUser,
            access_token: viutvAccessToken,
          })
        );
        // save user to the local storage
        localStorage.setItem("user", JSON.stringify(viutvUser));
        // save session to the local storage
        localStorage.setItem("access_token", JSON.stringify(viutvAccessToken));
      }
    }
  }, [isLogInShown, dispatch]);

  return (
    <button
      type="button"
      className={
        props.loginButtonText === "登入"
          ? "login_button"
          : "enter_button join_button"
      }
      style={{
        backgroundColor: colors.viublue,
        color: colors.whitecolor,
      }}
      onClick={() => {
        window.viutv.showMemberBox();
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setIsLogInShown(true);
              } else {
                setIsLogInShown(false);
              }
            });
          },
          { threshold: 0.5 }
        );
        observer.observe(document.getElementById("viutv-box-background"));
      }}
    >
      {props.loginButtonText}
    </button>
  );
}
