import "./Header.css";
import { colors } from "../color";
import NavigationBar from "../components/NavigationBar";
import Profile from "../components/Profile";

export default function Header(props) {
  return (
    <div
      className="fixed_header"
      style={{
        backgroundColor: colors.whitecolor,
      }}
    >
      <div className="header">
        <div className="left_header">
          <svg
            width="133"
            height="40"
            viewBox="0 0 133 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_133_196)">
              <path
                d="M132.587 11.6178H130.248C129.936 11.6178 129.667 11.8019 129.544 12.0663L126.577 19.2111C126.474 19.4566 126.228 19.6289 125.945 19.6289C125.662 19.6289 125.416 19.4566 125.312 19.2111L122.345 12.0663C122.223 11.8019 121.953 11.6178 121.642 11.6178H109.599C109.37 11.6178 109.184 11.8043 109.184 12.0332V14.193C109.184 14.4219 109.37 14.6084 109.599 14.6084H113.3V22.4212C113.3 22.6502 113.487 22.8367 113.716 22.8367H115.873C116.102 22.8367 116.289 22.6502 116.289 22.4212V14.6084H120.013L122.924 21.0097C123.495 22.0955 124.633 22.8367 125.945 22.8367C127.257 22.8367 128.395 22.0955 128.966 21.0097L132.953 12.2268C132.984 12.1678 133 12.104 133 12.0332C133 11.8043 132.814 11.6178 132.585 11.6178H132.587Z"
                fill={colors.viublue}
              />
              <path
                d="M28.1334 22.0506C28.1334 20.1104 27.0406 18.4274 25.4355 17.5847L12.4558 11.6932C12.3708 11.6484 12.2741 11.6224 12.1702 11.6224C11.8327 11.6224 11.5565 11.8962 11.5565 12.2361V15.6917C11.5565 16.152 11.8303 16.5485 12.2221 16.7303L22.7801 21.1159C23.1436 21.2693 23.3985 21.6304 23.3985 22.0506C23.3985 22.4707 23.1436 22.8319 22.7801 22.9877L12.2221 27.3732C11.8303 27.555 11.5565 27.9515 11.5565 28.4118V31.8674C11.5565 32.2073 11.8327 32.4811 12.1702 32.4811C12.2741 32.4811 12.3708 32.4551 12.4558 32.4103L25.4355 26.5188C27.0406 25.6761 28.1334 23.9932 28.1334 22.0529V22.0506Z"
                fill={colors.viublue}
              />
              <path
                d="M35.7456 22.0506C35.7456 31.9217 27.7439 39.9234 17.8728 39.9234C8.00168 39.9234 0 31.9193 0 22.0506C0 12.1819 8.00168 4.17548 17.8728 4.17548C27.7439 4.17548 35.7456 12.1772 35.7456 22.0506ZM17.8728 7.24161C9.61618 7.24161 2.92215 13.8719 2.92215 22.0506C2.92215 30.2293 9.61618 36.8573 17.8728 36.8573C26.1294 36.8573 32.8234 30.227 32.8234 22.0506C32.8234 13.8743 26.1294 7.24161 17.8728 7.24161Z"
                fill={colors.viublue}
              />
              <path
                d="M73.1765 11.6344H77.5715C77.9964 11.6344 78.3434 11.979 78.3434 12.4062V31.6952C78.3434 32.1201 77.9988 32.4671 77.5715 32.4671H73.1765C72.7516 32.4671 72.4047 32.1224 72.4047 31.6952V12.4039C72.4047 11.979 72.7493 11.632 73.1765 11.632V11.6344Z"
                fill={colors.viublue}
              />
              <path
                d="M54.7985 32.4671C57.2344 32.4671 59.3493 31.091 60.4091 29.0752L67.8136 12.765C67.8702 12.6564 67.9009 12.5361 67.9009 12.4062C67.9009 11.9814 67.5563 11.6344 67.1314 11.6344H62.7907C62.2124 11.6344 61.712 11.9767 61.4854 12.47L55.9763 25.7353C55.7827 26.1932 55.3295 26.5142 54.8008 26.5142C54.2721 26.5142 53.8189 26.1932 53.6253 25.7353L48.1162 12.47C47.8873 11.9767 47.3892 11.6344 46.8109 11.6344H42.4702C42.0453 11.6344 41.6984 11.979 41.6984 12.4062C41.6984 12.5361 41.7314 12.6588 41.7881 12.765L49.1926 29.0752C50.2524 31.091 52.3673 32.4671 54.8032 32.4671H54.7985Z"
                fill={colors.viublue}
              />
              <path
                d="M78.9335 3.55945C78.9335 5.52564 77.3402 7.1189 75.374 7.1189C73.4078 7.1189 71.8146 5.52564 71.8146 3.55945C71.8146 1.59325 73.4078 0 75.374 0C77.3402 0 78.9335 1.59325 78.9335 3.55945Z"
                fill={colors.viublue}
              />
              <path
                d="M94.7056 32.4671C101.008 32.4671 105.755 28.5016 105.755 23.6086V12.4062C105.755 11.9814 105.41 11.6344 104.983 11.6344H100.585C100.158 11.6344 99.8135 11.979 99.8135 12.4062L99.8088 22.5653C99.8088 24.7534 97.5216 26.526 94.7033 26.526C91.885 26.526 89.5978 24.7534 89.5978 22.5653L89.593 12.4062C89.593 11.9814 89.2484 11.6344 88.8212 11.6344H84.4238C83.999 11.6344 83.652 11.979 83.652 12.4062V23.6086C83.652 28.5016 88.3987 32.4671 94.7009 32.4671H94.7056Z"
                fill={colors.viublue}
              />
            </g>
            <defs>
              <clipPath id="clip0_133_196">
                <path
                  d="M0 0H133C133 22.0914 115.091 40 93 40H0V0Z"
                  fill={colors.viublue}
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <NavigationBar scrolledIndex={props.scrolledIndex} />
        <Profile />
      </div>
    </div>
  );
}
