import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./LuckdrawCarousel.css";
import "./CheerCarousel.css";
import "./WithScrollbar.css";

import { colors } from "../color";
import { useState, useEffect, useRef } from "react";
import PopupModel from "./PopupModel";

export default function CheerCarousel() {
  const [prizeList, setPrizeList] = useState([]);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const [clickedPrize, setClickedPrize] = useState(null);

  useEffect(() => {
    // add 10 prizes to the prizeList
    const tempPrizeList = [];
    for (let i = 0; i < 10; i++) {
      tempPrizeList.push({
        id: i,
        name: `Prize ${i}`,
        description: `Description of Prize ${i}`,
        image: `https://via.placeholder.com/150?text=Prize${i}`,
      });
    }
    setPrizeList(tempPrizeList);
    // fetch("https://api.example.com/prizes")
    //     .then((response) => response.json())
    //     .then((data) => {
    //         setPrizeList(data);
    //     });
  }, []);

  const [additionalTransfrom, setAdditionalTransfrom] = useState(0);
  const carouselRef = useRef(null);

  const CustomSlider = ({ carouselState }) => {
    let value = 0;
    let carouselItemWidth = 0;
    if (carouselRef.current) {
      carouselItemWidth = carouselRef.current.state.itemWidth;
      const maxTranslateX = Math.round(
        carouselItemWidth *
          (carouselRef.current.state.totalItems -
            carouselRef.current.state.slidesToShow) +
          150
      );
      value = maxTranslateX / 100;
    }

    const { transform } = carouselState;

    const handleSliderChange = (e) => {
      if (carouselRef.current.isAnimationAllowed) {
        carouselRef.current.isAnimationAllowed = false;
      }
      const nextTransform = e.target.value * value;
      const nextSlide = Math.round(nextTransform / carouselItemWidth);

      if (e.target.value === 0 && additionalTransfrom === 150) {
        carouselRef.current.isAnimationAllowed = true;
        setAdditionalTransfrom(0);
      }

      carouselRef.current.setState({
        transform: -nextTransform,
        currentSlide: nextSlide,
      });
    };

    return (
      <div className="custom-slider">
        <input
          type="range"
          value={Math.round(Math.abs(transform) / value)}
          defaultValue={0}
          max={
            (carouselItemWidth *
              (carouselState.totalItems - carouselState.slidesToShow) +
              (additionalTransfrom === 150 ? 0 : 150)) /
            value
          }
          onChange={handleSliderChange}
          className="custom-slider__input"
        />
      </div>
    );
  };
  return (
    <div>
      <Carousel
        ref={carouselRef}
        customButtonGroup={<CustomSlider />}
        // containerClass="carousel-container-with-scrollbar"
        additionalTransfrom={-additionalTransfrom}
        beforeChange={(nextSlide) => {
          if (nextSlide !== 0 && additionalTransfrom !== 150) {
            setAdditionalTransfrom(150);
          }
          if (nextSlide === 0 && additionalTransfrom === 150) {
            setAdditionalTransfrom(0);
          }
        }}
        // additionalTransfrom={0}
        arrows={false}
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container cheer_carousel_container"
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass="item_luckdraw"
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={true}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 2,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {/* <PrizeCard /> */}
        {prizeList.map((prize) => (
          <button
            type="button"
            className="prize_container prize_card"
            key={prize.id}
            onClick={() => {
              console.log("clicked");
              setOpen((o) => !o);
              setClickedPrize(prize);
            }}
          >
            <div className="prize_img_container">
              <img className="prize_img" src={prize.image} alt={prize.name} />
            </div>
            <div className="prize_name">{prize.name}</div>
            <div className="entire_table_container card_table_container">
              <div className="content_table_container">
                <div
                  className="content_table_key"
                  style={{ color: colors.grey2color }}
                >
                  每份價值
                </div>
                <div className="content_table_value">HK$ 160,377</div>
              </div>
              <div className="content_table_container">
                <div
                  className="content_table_key"
                  style={{ color: colors.grey2color }}
                >
                  數量
                </div>
                <div className="content_table_value">200</div>
              </div>
              <div className="content_table_container">
                <div
                  className="content_table_key"
                  style={{ color: colors.grey2color }}
                >
                  集氣日期
                </div>
                <div
                  className="content_table_value"
                  style={{ color: colors.viublue }}
                >
                  7月27日
                </div>
              </div>
              <div className="content_table_container">
                <div
                  className="content_table_key"
                  style={{ color: colors.grey2color }}
                >
                  集氣時間
                </div>
                <div
                  className="content_table_value"
                  style={{ color: colors.viublue }}
                >
                  15:00 - 21:00
                </div>
              </div>
            </div>
          </button>
        ))}
      </Carousel>
      <PopupModel
        prizeProps={{ open: open, clickedPrize: clickedPrize }}
        closeModal={closeModal}
      />
    </div>
  );
}
