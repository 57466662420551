import "./LuckdrawProgress.css";
import { colors } from "../color";
import coins from "../assets/coins.svg";
import ProgressBar from "@ramonak/react-progress-bar";

export default function LuckdrawProgress(props) {
  // return different color based on the props
  const getProgressBg = () => {
    if (props.position === "desktop") {
      return {
        backgroundColor: "rgba(73, 166, 255, 0.30)",
        color: colors.whitecolor,
      };
    } else {
      return {
        backgroundColor: colors.whitecolor,
        color: colors.blackcolor,
      };
    }
  };

  return (
    <div className="progress_container" style={getProgressBg()}>
      <img
        src={coins}
        alt="tickets"
        style={{ width: "40px", height: "40px" }}
      />
      <div className="progress_bar_container">
        <div
          className="progress_title"
          style={
            props.position === "desktop"
              ? { color: colors.whitecolor }
              : { color: colors.viublue }
          }
        >
          累積終極大抽獎資格進度
        </div>
        <ProgressBar
          completed={30}
          isLabelVisible={false}
          bgColor={colors.viublue}
          baseBgColor={colors.lightblue}
          height="8px"
        />
        <div className="progress_value">
          <div>已獲取印花</div>
          <div>120/3500個</div>
        </div>
      </div>
    </div>
  );
}
