import "./PrizeList.css";
import { colors } from "../color";
import coins from "../assets/coins.svg";
import Popup from "reactjs-popup";
import close from "../assets/Close.svg";
import LoginButton from "./LoginButton";

export default function PopupModel({ prizeProps, closeModal }) {
  return (
    <Popup
      open={prizeProps.open}
      modal
      {...{
        overlayStyle: {
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: "999999",
        },
      }}
      onClose={closeModal}
    >
      <div
        className="popup_entire_container"
        style={{ backgroundColor: colors.whitecolor }}
      >
        <div className="popup_header">
          <div className="popup_header_title">大抽獎禮物詳情</div>
          <button
            type="button"
            style={{ backgroundColor: colors.whitecolor }}
            onClick={closeModal}
          >
            <img src={close} alt={"close"} />
          </button>
        </div>
        {prizeProps.clickedPrize && (
          <div className="popup_content">
            <div className="popup_img_container">
              <img
                className="prize_img"
                src={`https://via.placeholder.com/150?text=${prizeProps.clickedPrize.id}`}
                alt={prizeProps.clickedPrize.id}
              />
            </div>
            <div className="popup_content_container">
              <div className="pop_desc_container">
                <div>
                  <div className="content_title">小米手環 8</div>
                  <div style={{ marginTop: "8px" }}>
                    由 Neta 香港贊助。純電動的動感駕駛享受 ·
                    ​充滿智能與趣味，電動的動感駕駛享受 · ​充滿智能與趣味。
                  </div>
                </div>
                <div className="entire_table_container">
                  <div className="content_table_container">
                    <div
                      className="content_table_key"
                      style={{ color: colors.grey2color }}
                    >
                      每份價值
                    </div>
                    <div className="content_table_value">HK$ 160,377</div>
                  </div>
                  <div className="content_table_container">
                    <div
                      className="content_table_key"
                      style={{ color: colors.grey2color }}
                    >
                      數量
                    </div>
                    <div className="content_table_value">200</div>
                  </div>
                  <div className="content_table_container">
                    <div
                      className="content_table_key"
                      style={{ color: colors.grey2color }}
                    >
                      集氣日期
                    </div>
                    <div
                      className="content_table_value"
                      style={{ color: colors.viublue }}
                    >
                      7月27日
                    </div>
                  </div>
                  <div className="content_table_container">
                    <div
                      className="content_table_key"
                      style={{ color: colors.grey2color }}
                    >
                      集氣時間
                    </div>
                    <div
                      className="content_table_value"
                      style={{ color: colors.viublue }}
                    >
                      15:00 - 21:00
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="content_desc"
                    style={{ color: colors.grey2color }}
                  >
                    如何領取
                  </div>
                  <div
                    className="content_desc"
                    style={{ marginTop: "8px", color: colors.grey2color }}
                  >
                    乞發山，邊綠色澡前住躲，朵各世問法申，奶訴音花才王造九前飯止米卜。
                  </div>
                </div>
                <div>
                  <div
                    className="content_desc"
                    style={{ color: colors.grey2color }}
                  >
                    條款與細則
                  </div>
                  <div
                    className="content_desc"
                    style={{ marginTop: "8px", color: colors.grey2color }}
                  >
                    乞發山，邊綠色澡前住躲，朵各世問法申，奶訴音花才王造九前飯止米卜。交浪直牠斗」占許苗乞個水穿吹右活奶實家。和加內魚太亮。
                    澡皮八明哭汁安笑孝可：眼者汗家卜飽黑反面民姐福布頭即，哪至且！扒第呀姐？直燈方食虎把着冬戊位？從收唱麼結做歌，院裝波父良躲豆課什哭生叫荷頁衣每汁神地內。
                  </div>
                </div>
              </div>
              <div className="popup_redeem_container">
                <div className="left_prize_container">
                  <div
                    className="prize_description"
                    style={{
                      marginRight: "4px",
                      color: colors.blackgreycolor,
                    }}
                  >
                    需要印花
                  </div>
                  <img src={coins} alt="tickets" />
                  <div className="stamp_number">45</div>
                </div>
                <LoginButton loginButtonText="確認兌換" />
              </div>
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
}
