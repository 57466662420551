export const colors = {
  darkblue: "#08194C",
  darkbluelight: "#2A3E79",
  viublue: "#0A7DEB",
  viulight: "#D2E9FF",
  lightblue: "#F0F7FF",

  highlightorange: "#E67A55",
  grey2color: "#676767",

  //wireframe
  whitecolor: "#FFFFFF",
  lightgreycolor: "#F2F2F2",
  greycolor: "#E4E4E4",
  middlegreycolor: "#D9D9D9",
  darkgreycolor: "#D9D9D9",
  blackgreycolor: "#797979",

  lightcolor: "#F8F8F8",
  darkcolor: "#28272F",
  blackcolor: "#000000",
};
